import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import Countdown from 'react-countdown';


import bodymovin from 'lottie-web'
import Lottie from 'react-lottie-player'



import {Link} from 'gatsby'

import Topbar from '../components/topbar'
import Footer from '../components/footer'
import LogoSlider from '../components/logoSlider'
import ContactForm from '../components/contactForm'
import WhatsappButton from '../components/whatsappButton'
import { APP_URI } from '../configs';

import ilustration from '../images/ilustration.jpg'
import ilustration1 from '../images/ilustration-1.png'
import ilustration2 from '../images/ilustration-2.png'
import ilustration3 from '../images/ilustration-3.png'
import ilustration4 from '../images/ilustration-4.png'
import anim from '../images/troopy-anim.gif'

import lottieJson from '../animations/troopy.json'
import tanque from '../animations/tanque/animation.json'


import '../scss/home.scss'

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.references = {
      animRef: React.createRef(),
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    new WOW.WOW({
        live: false,
        offset: 500
    }).init();
  }

  scrollToReference(ref) {
    const node = findDOMNode(ref.current);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  componentDidMount(){
    const anim = bodymovin.loadAnimation({
      container: this.references.animRef.current,
      renderer: 'svg',
      autoplay: true,
      loop: true,
      path: '/tanque/animation.json'
    })

  }

  renderer({ days, hours, minutes, seconds, completed }){
    if (completed) {
      // Render a completed state
      return <></>
    } else {
      // Render a countdown
      return <div className="countdown">
                <div className="countdown--time inv">
                </div>
                <div className="countdown--time">
                  <span className="number">
                  {days}
                  </span>
                  <span className="text">
                  días
                  </span>
                </div>
                <div className="countdown--time">
                <span className="number">
                  {hours}
                  </span>
                  <span className="text">
                    horas
                  </span>
                </div>
                <div className="countdown--time">
                <span className="number">
                  {minutes}
                  </span>
                  <span className="text">
                    minutos
                  </span>
                </div>
                <div className="countdown--time">
                <span className="number">
                  {seconds}
                  </span>
                  <span className="text">
                    segundos
                  </span>
                </div>
                <div className="countdown--time inv">
                </div>
            </div>;
    }
  };

  render(){
    return(
    <div className="homepage">
      <div className="content homepage-content">
      <Topbar handlerNavigation={(ref) => this.scrollToReference(ref)} pageReferences={this.references}/>
      <Helmet>

      </Helmet>

      <section ref={this.references.hero} className="hero--section">
        <div className="wrapper">
          <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div class="hero--content">
            <h1 className="hero--title">¡Conectá, Jugá, Ganá!</h1>
            <p className="hero--text">Conectá y conocé a otros jugadores, creá equipos competitivos, conocé nuevos compañeros y competí para convertirte en el gamer que siempre soñaste.</p>
            <Link to="/feed" className="button--global pink">Usá Troop Web</Link>
            </div>
            <Lottie className="anim troopy" loop play animationData={lottieJson} />
            <div className="anim tanque" ref={this.references.animRef} loop play animationData={tanque} />
          </div>
        </div>
      </section>

      <section className="features--section">
        <div className="wrapper">
        <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--img left">
              <img src={ilustration1} alt="técnicas de mediación"></img>
            </div>
            <div className="div--feature">
              <h3 className="feature--title">Tu perfil, tus clips, tus stats</h3>
              <p className="feature--text">
                Creá tu perfil gamer. Vinculá los juegos en los que más te
                destacás, mostrá tus estadísticas y subí tus mejores clips para
                aplicar a los mejores equipos y conocer gente de tu nivel.
              </p>
            </div>
          </div>
          <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--feature">
              <h3 className="feature--title">Encontrá jugadores de tu nivel</h3>
              <p className="feature--text">
              Conectá y conocé a otros jugadores, creá equipos competitivos, conocé nuevos compañeros y competí para convertirte en el gamer que siempre soñaste.
              </p>
            </div>
            <div className="div--img">
              <img src={ilustration2} alt="implementación precisa de estrategias"></img>
            </div>
          </div>
          <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--img left">
              <img src={ilustration3} alt="técnicas de mediación"></img>
            </div>
            <div className="div--feature">
              <h3 className="feature--title">Subí tu clip y editalo online</h3>
              <p className="feature--text">
              Llevá a tu equipo y compañeros en la App. Arreglá días para jugar,
              preguntá en tu grupo cuando te falte uno y programá días para
              jugar, entrenar y divertirte. Ya no vas a tener que usar distintas
              aplicaciones cuando quieras reunir a tu equipo.
              </p>
            </div>
          </div>
         </div>
      </section>
      
      <section className="logos--section">
        <div className="wrapper wow fadeIn" data-wow-offset="200">
          <div className="logo--title">
            <h2 className="title--present">Sumate a la <span>Troop CUP 21</span></h2>
            <h2 className="text--present">Conectá y conocé a otros jugadores, creá equipos competitivos, conocé nuevos
compañeros y competí para convertirte en el gamer que siempre soñaste.</h2>
          </div>    
          <Countdown
            daysInHours={false}
            date={new Date("December 11, 2023 14:00:00")}
            renderer={this.renderer}
          />
            <Link to="/troop-cup" className="button--global yellow">Más Información</Link>
          {/* <LogoSlider/> */}
        </div>       
      </section>

      <section className="features--section">
        <div className="wrapper">
          <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--feature">
              <h3 className="feature--title">Tu equipo al alcance de tu mano</h3>
              <p className="feature--text">
                Creá tu perfil gamer. Vinculá los juegos en los que más te
                destacás, mostrá tus estadísticas y subí tus mejores clips para
                aplicar a los mejores equipos y conocer gente de tu nivel.
              </p>
              <Link to="/wip" className="button--global">Descargá la App</Link>
            </div>
            <div className="div--img">
              <img className="bg" src={ilustration4} alt="estudio de cada deudor"></img>
            </div>
          </div>
        </div>
      </section>
      

      <Footer/>
    </div>
    </div>
    );
  }
}
