import React, {Fragment} from "react"

import {WPP_API_URI, WPP_CONTACT_PHONE, WPP_CONTACT_MESSAGE} from '../../configs'

import './scss/whatsappButton.scss'

import wpLogo from '../../images/whatsapp.png'

class WhatsappButton extends React.Component {
  render(){
    return(
      <a className={`whatsapp--button`} href={WPP_API_URI+WPP_CONTACT_PHONE+'?text='+WPP_CONTACT_MESSAGE}  target="_blank" >
        <img src={wpLogo}></img>
      </a>
    );
  }
}

export default WhatsappButton;
