import React from "react"

import './scss/textInput.scss'

export default class TextInput extends React.Component {

  getErrorText(errorCode){
    switch (errorCode) {
      case 1:
        return('Entrada no valida');
        break;
      case 2:
        return('Campo Requerido');
        break;
      default:
        return('Entrada no valida');
    }
  }

  render(){
    return(
      <div className={`text--input ${this.props.inputState.error ? ' error' : ''}`} >
        <p className='input--name'>{this.props.inputText}</p>
        {this.props.large ?
          <textarea
            className={`${this.props.inputState.error ? ' error' : ''}`}
            value={this.props.inputState.value}
            placeholder={`ingrese su ${this.props.inputText}`}
            onChange={this.props.handlerChange}>
          </textarea>
        :
          <input
            className={`${this.props.inputState.error ? ' error' : ''}`}
            value={this.props.inputState.value}
            placeholder={`ingrese su ${this.props.inputText}`}
            onChange={this.props.handlerChange}>
          </input>
        }
        {(this.props.inputState.error != 0) &&
          <div className={'error--msg'}>{this.getErrorText(this.props.inputState.error)}</div>
        }
      </div>
    );
  }
}
