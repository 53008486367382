import axios from 'axios';

export const sendContactMail = async (data) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('message', data.message);
  const apiResponse = await axios.post('/api/mail/post.php', formData);
  return apiResponse;
}
  