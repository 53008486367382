import React, {Fragment} from "react"

import TextInput from './textInput'
import {sendContactMail} from './mailer'

import './scss/contactForm.scss'

const validationRules = [
  {
    inputName: "name",
    regex: /^/,
    maxLength: 15,
    minLength: 3, //0 for non-required fields
  },
  {
    inputName: "email",
    regex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    maxLength: 100,
    minLength: 3,
  },
  {
    inputName: "phone",
    regex: /^[0-9 -]+$/,
    maxLength: 20,
    minLength: 0,
  },
  {
    inputName: "message",
    regex: /^/,
    maxLength: 550,
    minLength: 5,
  },
]


class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      inputs:
        [
          {
            inputName: "name",
            value: "",
            error: false
          },
          {
            inputName: "email",
            value: "",
            error: false
          },
          {
            inputName: "phone",
            value: "",
            error: false
          },
          {
            inputName: "message",
            value: "",
            error: false
          },
        ],
    };
  }

  handlerInputChange(arrayIndex, value){
    var formInputs = this.state.inputs;
    formInputs[arrayIndex].value = value;
    this.setState({
      inputs: formInputs,
    })
  }

  validateInputs(){
    var formInputs = this.state.inputs;
    var errors = 0;
    this.state.inputs.map(function(input, i){
      var value = input.value;
      var regex = validationRules[i].regex;
      var error = 1; //invalid input
      if(value.length === 0 && validationRules[i].minLength > 0){
        error = 2; //empty on required
      }
      if (value && (value.match(regex)) && (value.length >= validationRules[i].minLength) && (value.length <= validationRules[i].maxLength)){
        error = 0; //valid
      }
      if(value.length === 0 && validationRules[i].minLength === 0){
        error = 0; //valid
      }
      formInputs[i].error = error;
      if(error){
        errors++;
      }
    });
    this.setState({
      inputs: formInputs,
    })
    return errors;
  }

  async onSubmit(){
    var data = {
      name: this.state.inputs[0].value,
      email: this.state.inputs[1].value,
      phone: this.state.inputs[2].value,
      message: this.state.inputs[3].value,
    }
    if(this.validateInputs() === 0){
      const apiResponse = await sendContactMail(data);
      if (apiResponse?.status == 200){
        this.setState({
          sent: true,
        })
      }
    }
  }

  render(){
    return(
      <div className={`contact--form`} >
        { !this.state.sent ?
        <Fragment>
          <TextInput inputText="Nombre Completo" handlerChange={(e) => this.handlerInputChange(0, e.target.value)} inputState={this.state.inputs[0]}/>
          <TextInput inputText="E-Mail" handlerChange={(e) => this.handlerInputChange(1, e.target.value)} inputState={this.state.inputs[1]}/>
          <TextInput inputText="Telefono" handlerChange={(e) => this.handlerInputChange(2, e.target.value)} inputState={this.state.inputs[2]}/>
          <TextInput inputText="Mensaje" large={true} handlerChange={(e) => this.handlerInputChange(3, e.target.value)} inputState={this.state.inputs[3]}/>
          <button className="submit--button" onClick={() => this.onSubmit()}> Enviar</button>
        </Fragment>
          :
          <h2 className="success--text">¡Gracias! Responderemos tu consulta lo antes posible</h2>
        }
      </div>
    );
  }
}

export default ContactForm;
